import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// import "antd/dist/antd.variable.min.css";

// This system id dependent on bootstrap styles and js
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import WrapperApp from "WrapperApp";
import "./index.css";
import store, { persistor } from "./redux";

// translation
import "config/i18n";

import * as Sentry from "@sentry/react";
import { ignoreErrors } from "sentryUtils";
import { PersistGate } from "redux-persist/integration/react";
import LoadingPage from "components/common/components/loading";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [
      new Sentry.browserTracingIntegration({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/apply.loyac.org/],
      }),
    ],
    release: "loyac-app-v1.4.9",
    ignoreErrors: ignoreErrors,
    // Performance Monitoring
    tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<LoadingPage />}>
        <BrowserRouter>
          <WrapperApp />
        </BrowserRouter>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
